// ** React Imports
import "dotenv/config";
import { Suspense, lazy, useEffect, useState } from "react";
import { createRoot } from 'react-dom/client';
// ** Redux Imports
import { Provider } from "react-redux";
import { store } from "./redux/storeConfig/store";

import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { getMainDefinition } from "apollo-utilities";
import { ApolloLink, split } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { RetryLink } from "apollo-link-retry";
import { WebSocketLink } from "@apollo/client/link/ws";
import { onError } from "apollo-link-error";
import { InMemoryCache } from "apollo-cache-inmemory";
import { persistCache } from "apollo-cache-persist";
import history from "./constants/history";

// ** Intl, CASL & ThemeColors Context
import ability from "./configs/acl/ability";
import { toast, ToastContainer } from "react-toastify";
import { AbilityContext } from "./utility/context/Can";
import { ThemeContext } from "./utility/context/ThemeColors";
import { ActionBtnProviderWrapper } from "./utility/context/ActionBtn";
import { IntlProviderWrapper } from "./utility/context/Internationalization";
import { CookiesProvider } from "react-cookie";
// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner";
// ** Ripple Button
import "./@core/components/ripple-button";
import { signOutClearStorage } from "@utils";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Toastify
import "react-toastify/dist/ReactToastify.css";

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";

// ** Service Worker
import * as serviceWorker from "./serviceWorker";
import { FormatError } from "./ui/components/Comman/FormatError";
// import { SocketContext, socket } from './socket';

// ** Skeleton
import { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import * as Sentry from "@sentry/react";


// socket.on("connect", () => {
// });

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));
// let location = useLocation()
const signOut = async (client) => {
	history.push("/");
	signOutClearStorage(client);
};

const loginUser = JSON.parse(localStorage.getItem("user"));
const retry = new RetryLink({ attempts: { max: Infinity } });
const httpLink = new HttpLink({
	uri: `${process.env.REACT_APP_GQL_URL}`,
});

const wsLink = new WebSocketLink({
	uri: `${process.env.REACT_APP_SOCKET_URL}`,
	options: {
		reconnect: true,
		keepAlive: 30000,
		connectionParams: {
			authentication: localStorage.getItem("token"),
		},
	},
});

const terminatingLink = split(
	({ query }) => {
		const { kind, operation } = getMainDefinition(query);
		return kind === "OperationDefinition" && operation === "subscription";
	},
	wsLink,
	httpLink,
	retry
);

const authLink = new ApolloLink((operation, forward) => {
	operation.setContext(({ headers = {} }) => {
		const token = localStorage.getItem("token");
		if (token) {
			headers = { ...headers, "x-token": token, path: window?.location?.pathname };
		}
		return { headers };
	});

	return forward(operation);
});

const createRequestTracker = () => {
	let activeRequests = 0;
	let subscribers = [];

	const notify = () => {
		subscribers.forEach(subscriber => subscriber(activeRequests));
	};

	return {
		link: new ApolloLink((operation, forward) => {
			activeRequests++;
			notify();

			return forward(operation).map(response => {
				activeRequests--;
				notify();
				return response;
			});
		}),
		subscribe: (callback) => {
			subscribers.push(callback);
			return () => {
				subscribers = subscribers.filter(sub => sub !== callback);
			};
		}
	};
};

const requestTracker = createRequestTracker();

export const useActiveRequests = () => {
	const [count, setCount] = useState(0);

	useEffect(() => {
		const unsubscribe = requestTracker.subscribe(setCount);
		return unsubscribe;
	}, []);

	return count;
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors) {
		graphQLErrors.forEach(({ message, extensions, path }) => {
			if (extensions.code === "UNAUTHENTICATED") {
				if (loginUser?.roleName) {
					signOut(client);
					toast.error(FormatError(message));
				}
			}
		});
	}

	if (networkError) {
		if (networkError.statusCode === 400) {
			if (loginUser?.roleName) {
				signOut(client);
			}
		}
	}
});

const link = ApolloLink.from([requestTracker.link, authLink, errorLink, terminatingLink]);

const cache = new InMemoryCache({
	addTypename: false,
});
const storage = window.localStorage;
const waitOnCache = persistCache({ cache, storage });

export const client = new ApolloClient({
	link,
	cache,
});


Sentry.init({
	dsn: "https://cf2f4ae0d2a1c11c8707ec333e9b18dc@o4507412753219584.ingest.us.sentry.io/4507412758593536",
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["app.constructieshop"],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


const container = document.getElementById('root');
const root = createRoot(container);

function AppWithCallbackAfterRender() {
	useEffect(() => {
		console.log('rendered');
	});

	return <ApolloProvider client={client}>
		<Provider store={store}>
			<Suspense fallback={<Spinner />}>
				<AbilityContext.Provider value={ability}>
					<ThemeContext>
						<IntlProviderWrapper>
							<ActionBtnProviderWrapper>
								<CookiesProvider>
									<LazyApp />
									<ToastContainer newestOnTop theme="light" />
									<SkeletonTheme />
								</CookiesProvider>
							</ActionBtnProviderWrapper>
						</IntlProviderWrapper>
					</ThemeContext>
				</AbilityContext.Provider>
			</Suspense>
		</Provider>
	</ApolloProvider>
}

root.render(<AppWithCallbackAfterRender />);

// ReactDOM.render(
// 	// <SocketContext.Provider value={socket}>
// 	<ApolloProvider client={client}>
// 		<Provider store={store}>
// 			<Suspense fallback={<Spinner />}>
// 				<AbilityContext.Provider value={ability}>
// 					<ThemeContext>
// 						<IntlProviderWrapper>
// 							<RefreshBtnProviderWrapper>
// 								<RefreshUnityChecksBtnProviderWrapper>
// 									<ActionBtnProviderWrapper>
// 										<CookiesProvider>
// 											<LazyApp />
// 											<ToastContainer newestOnTop theme="light" />
// 											<SkeletonTheme />
// 										</CookiesProvider>
// 									</ActionBtnProviderWrapper>
// 								</RefreshUnityChecksBtnProviderWrapper>
// 							</RefreshBtnProviderWrapper>
// 						</IntlProviderWrapper>
// 					</ThemeContext>
// 				</AbilityContext.Provider>
// 			</Suspense>
// 		</Provider>
// 	</ApolloProvider>,
// 	// </SocketContext.Provider>
// 	document.getElementById("root")
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
